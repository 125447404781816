import request from '@/utils/request'

export function getDiscountConfig() {
  return request({
    url: '/product/discount/config',
    method: 'get'
  })
}

export function updateDiscountConfig(parameter) {
  return request({
    url: '/product/discount/config',
    method: 'post',
    data: parameter
  })
}

export function getMobileMember(parameter) {
  return request({
    url: '/member/query/mobile',
    method: 'GET',
    params: parameter
  })
}