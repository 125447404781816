<template>
  <!-- hidden PageHeaderWrapper title demo -->
  <page-header-wrapper :title='this.$route.meta.title'>
    <a-card :body-style="{padding: '24px 32px'}" :bordered='false'>
<!--      <a-form-model ref='form' :model='form' :rules='rules' layout='inline'>-->
<!--        <a-descriptions title='单品折扣' :column="24">-->
<!--          <a-descriptions-item :span="4">单次消费满4800</a-descriptions-item>-->
<!--          <a-descriptions-item :span="20">-->
<!--            <a-form-model-item prop='dpLevelOne' style="margin-bottom: 0;">-->
<!--              <a-input-number v-model='form.dpLevelOne' :min='0'-->
<!--                              :formatter='value => `${value}%`'-->
<!--                              :parser="value => value.replace('%', '')" />-->
<!--            </a-form-model-item>-->
<!--          </a-descriptions-item>-->
<!--          <a-descriptions-item :span="4">单次消费满32000</a-descriptions-item>-->
<!--          <a-descriptions-item :span="20">-->
<!--            <a-form-model-item prop='dpLevelTwo' style="margin-bottom: 0;">-->
<!--              <a-input-number v-model='form.dpLevelTwo' :min='0'-->
<!--                              :formatter='value => `${value}%`'-->
<!--                              :parser="value => value.replace('%', '')" />-->
<!--            </a-form-model-item>-->
<!--          </a-descriptions-item>-->
<!--          <a-descriptions-item :span="4">单次消费满68000</a-descriptions-item>-->
<!--          <a-descriptions-item :span="20">-->
<!--            <a-form-model-item prop='dpLevelThree' style="margin-bottom: 0;">-->
<!--              <a-input-number v-model='form.dpLevelThree' :min='0'-->
<!--                              :formatter='value => `${value}%`'-->
<!--                              :parser="value => value.replace('%', '')" />-->
<!--            </a-form-model-item>-->
<!--          </a-descriptions-item>-->
<!--          <a-descriptions-item :span="24">-->
<!--            <a-input v-model='mobile' style="width: 200px" placeholder="用户手机号" @change="profile = ''" />-->
<!--            <a-button style="margin-left: 20px" type='primary' @click='handleMobile'>-->
<!--              查询-->
<!--            </a-button>-->
<!--            <template v-if="mobile.length == 11 && profile">-->
<!--              <span style="margin-left: 80px">折扣：{{profile.productDiscountRate > 0 ? profile.productDiscountRate / 10  + '折' : '未获得'}}</span>-->
<!--              <a-switch v-if="profile.productDiscountRate > 0 && profile.type == 'normal'" style="margin-left: 20px" :checked="profile.discountUseTag == 1" @change="handleChangeUse" />-->
<!--              <a-popconfirm title='确定注销吗？' ok-text='确认' cancel-text='取消' @confirm='handelZhuXiao()' style="margin-left: 20px">-->
<!--                <a-button v-text="'注销'"/>-->
<!--              </a-popconfirm>-->

<!--            </template>-->
<!--          </a-descriptions-item>-->
<!--        </a-descriptions>-->
<!--        <a-divider style='margin-bottom: 32px' />-->
<!--        <a-descriptions title='商户待付款金额设置' :column="24">-->
<!--          <a-descriptions-item :span="6"><span style="font-weight: bold;">批发类型</span></a-descriptions-item>-->
<!--          <a-descriptions-item :span="6"><span style="font-weight: bold;">单次消费满4800</span></a-descriptions-item>-->
<!--          <a-descriptions-item :span="6"><span style="font-weight: bold;">单次消费满32000</span></a-descriptions-item>-->
<!--          <a-descriptions-item :span="6"><span style="font-weight: bold;">单次消费满68000</span></a-descriptions-item>-->
<!--          <a-descriptions-item :span="6">补贴</a-descriptions-item>-->
<!--          <a-descriptions-item :span="6">-->
<!--            <a-form-model-item prop='merchantBtLevelOne' style="margin-bottom: 0;">-->
<!--              <a-input-number v-model='form.merchantBtLevelOne' :min='0' />-->
<!--            </a-form-model-item>-->
<!--          </a-descriptions-item>-->
<!--          <a-descriptions-item :span="6">-->
<!--            <a-form-model-item prop='merchantBtLevelTwo' style="margin-bottom: 0;">-->
<!--              <a-input-number v-model='form.merchantBtLevelTwo' :min='0' />-->
<!--            </a-form-model-item>-->
<!--          </a-descriptions-item>-->
<!--          <a-descriptions-item :span="6">-->
<!--            <a-form-model-item prop='merchantBtLevelThree' style="margin-bottom: 0;">-->
<!--              <a-input-number v-model='form.merchantBtLevelThree' :min='0' />-->
<!--            </a-form-model-item>-->
<!--          </a-descriptions-item>-->
<!--          <a-descriptions-item :span="6">VIP</a-descriptions-item>-->
<!--          <a-descriptions-item :span="6">-->
<!--            <a-form-model-item prop='merchantVipLevelOne' style="margin-bottom: 0;">-->
<!--              <a-input-number v-model='form.merchantVipLevelOne' :min='0' />-->
<!--            </a-form-model-item>-->
<!--          </a-descriptions-item>-->
<!--          <a-descriptions-item :span="6">-->
<!--            <a-form-model-item prop='merchantVipLevelTwo' style="margin-bottom: 0;">-->
<!--              <a-input-number v-model='form.merchantVipLevelTwo' :min='0' />-->
<!--            </a-form-model-item>-->
<!--          </a-descriptions-item>-->
<!--          <a-descriptions-item :span="6">-->
<!--            <a-form-model-item prop='merchantVipLevelThree' style="margin-bottom: 0;">-->
<!--              <a-input-number v-model='form.merchantVipLevelThree' :min='0' />-->
<!--            </a-form-model-item>-->
<!--          </a-descriptions-item>-->
<!--          <a-descriptions-item :span="6">VIP商品折扣</a-descriptions-item>-->
<!--          <a-descriptions-item :span="6">-->
<!--            <a-form-model-item prop='merchantVipzLevelOne' style="margin-bottom: 0;">-->
<!--              <a-input-number v-model='form.merchantVipzLevelOne' :min='0' />-->
<!--            </a-form-model-item>-->
<!--          </a-descriptions-item>-->
<!--          <a-descriptions-item :span="6">-->
<!--            <a-form-model-item prop='merchantVipzLevelTwo' style="margin-bottom: 0;">-->
<!--              <a-input-number v-model='form.merchantVipzLevelTwo' :min='0' />-->
<!--            </a-form-model-item>-->
<!--          </a-descriptions-item>-->
<!--          <a-descriptions-item :span="6">-->
<!--            <a-form-model-item prop='merchantVipzLevelThree' style="margin-bottom: 0;">-->
<!--              <a-input-number v-model='form.merchantVipzLevelThree' :min='0' />-->
<!--            </a-form-model-item>-->
<!--          </a-descriptions-item>-->
<!--          <a-descriptions-item :span="6">尊享批发</a-descriptions-item>-->
<!--          <a-descriptions-item :span="6">-->
<!--            <a-form-model-item prop='merchantZxLevelOne' style="margin-bottom: 0;">-->
<!--              <a-input-number v-model='form.merchantZxLevelOne' :min='0' />-->
<!--            </a-form-model-item>-->
<!--          </a-descriptions-item>-->
<!--          <a-descriptions-item :span="6">-->
<!--            <a-form-model-item prop='merchantZxLevelTwo' style="margin-bottom: 0;">-->
<!--              <a-input-number v-model='form.merchantZxLevelTwo' :min='0' />-->
<!--            </a-form-model-item>-->
<!--          </a-descriptions-item>-->
<!--          <a-descriptions-item :span="6">-->
<!--            <a-form-model-item prop='merchantZxLevelThree' style="margin-bottom: 0;">-->
<!--              <a-input-number v-model='form.merchantZxLevelThree' :min='0' />-->
<!--            </a-form-model-item>-->
<!--          </a-descriptions-item>-->
<!--          <a-descriptions-item :span="6">尊享商品折扣</a-descriptions-item>-->
<!--          <a-descriptions-item :span="6">-->
<!--            <a-form-model-item prop='merchantZxzLevelOne' style="margin-bottom: 0;">-->
<!--              <a-input-number v-model='form.merchantZxzLevelOne' :min='0' />-->
<!--            </a-form-model-item>-->
<!--          </a-descriptions-item>-->
<!--          <a-descriptions-item :span="6">-->
<!--            <a-form-model-item prop='merchantZxzLevelTwo' style="margin-bottom: 0;">-->
<!--              <a-input-number v-model='form.merchantZxzLevelTwo' :min='0' />-->
<!--            </a-form-model-item>-->
<!--          </a-descriptions-item>-->
<!--          <a-descriptions-item :span="6">-->
<!--            <a-form-model-item prop='merchantZxzLevelThree' style="margin-bottom: 0;">-->
<!--              <a-input-number v-model='form.merchantZxzLevelThree' :min='0' />-->
<!--            </a-form-model-item>-->
<!--          </a-descriptions-item>-->
<!--        </a-descriptions>-->
<!--        <a-divider style='margin-bottom: 32px' />-->
<!--        <a-descriptions title='尊享转跳名额' :column="24">-->
<!--          <a-descriptions-item :span="6">云店</a-descriptions-item>-->
<!--          <a-descriptions-item :span="6">-->
<!--            <a-form-model-item prop='zxJumpStore' style="margin-bottom: 0;">-->
<!--              <a-input-number v-model='form.zxJumpStore' :min='0' />-->
<!--            </a-form-model-item>-->
<!--          </a-descriptions-item>-->
<!--          <a-descriptions-item :span="6">服务中心</a-descriptions-item>-->
<!--          <a-descriptions-item :span="6">-->
<!--            <a-form-model-item prop='zxJumpCenter' style="margin-bottom: 0;">-->
<!--              <a-input-number v-model='form.zxJumpCenter' :min='0' />-->
<!--            </a-form-model-item>-->
<!--          </a-descriptions-item>-->
<!--        </a-descriptions>-->
<!--        <a-divider style='margin-bottom: 32px' />-->
<!--        <a-descriptions title='推荐人为用户（有购买），服务收款价格' :column="24">-->
<!--          <a-descriptions-item :span="6">VIP特殊</a-descriptions-item>-->
<!--          <a-descriptions-item :span="6">-->
<!--            <a-form-model-item prop='vipSpecial' style="margin-bottom: 0;">-->
<!--              <a-input-number v-model='form.vipSpecial' :min='0' />-->
<!--            </a-form-model-item>-->
<!--          </a-descriptions-item>-->
<!--          <a-descriptions-item :span="6">尊享特殊</a-descriptions-item>-->
<!--          <a-descriptions-item :span="6">-->
<!--            <a-form-model-item prop='zxSpecial' style="margin-bottom: 0;">-->
<!--              <a-input-number v-model='form.zxSpecial' :min='0' />-->
<!--            </a-form-model-item>-->
<!--          </a-descriptions-item>-->
<!--        </a-descriptions>-->
<!--        <a-divider style='margin-bottom: 32px' />-->
<!--        <a-descriptions title='VIP商品折扣' :column="24">-->
<!--          <a-descriptions-item :span="6">批发订单达4800</a-descriptions-item>-->
<!--          <a-descriptions-item :span="6">-->
<!--            <a-form-model-item prop='vipCenterMemberPackage' style="margin-bottom: 0;">-->
<!--              <a-input-number v-model='form.vipCenterMemberPackage' :min='0' />-->
<!--            </a-form-model-item>-->
<!--          </a-descriptions-item>-->
<!--          <a-descriptions-item :span="9">-->
<!--            <a-form-model-item prop='vipCenterMemberQuota'>-->
<!--              获得-->
<!--              <a-input-number v-model='form.vipCenterMemberQuota' :min='0' />-->
<!--              个商品折扣-->
<!--            </a-form-model-item>-->
<!--          </a-descriptions-item>-->
<!--        </a-descriptions>-->
<!--        <a-divider style='margin-bottom: 32px' />-->
<!--        <a-descriptions title='尊享商品折扣' :column="24">-->
<!--          <a-descriptions-item :span="6">批发订单达32000</a-descriptions-item>-->
<!--          <a-descriptions-item :span="6">-->
<!--            <a-form-model-item prop='zxPresidentMemberPackage' style="margin-bottom: 0;">-->
<!--              <a-input-number v-model='form.zxPresidentMemberPackage' :min='0' />-->
<!--            </a-form-model-item>-->
<!--          </a-descriptions-item>-->
<!--          <a-descriptions-item :span="9">-->
<!--            <a-form-model-item prop='zxPresidentMemberQuota'>-->
<!--              获得-->
<!--              <a-input-number v-model='form.zxPresidentMemberQuota' :min='0' />-->
<!--              个商品折扣-->
<!--            </a-form-model-item>-->
<!--          </a-descriptions-item>-->
<!--        </a-descriptions>-->
<!--        <a-divider style='margin-bottom: 32px' />-->
<!--      </a-form-model>-->
<!--      <a-form-model-item :wrapper-col='{ lg: { span: 12 ,offset: 5}, sm: { span: 12 ,offset: 5} }'>-->
<!--        <a-button @click='handleReset'>-->
<!--          重置-->
<!--        </a-button>-->
<!--        <a-divider type='vertical' />-->
<!--        <a-button @click='handleCancel'>-->
<!--          取消-->
<!--        </a-button>-->
<!--        <a-divider type='vertical' />-->
<!--        <a-button type='primary' :loading="loading" @click='handleSubmit'>-->
<!--          提交-->
<!--        </a-button>-->
<!--      </a-form-model-item>-->
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { getDiscountConfig, updateDiscountConfig, getMobileMember } from '@/api/discount'
import { putMember,deleteShareMember } from '@/api/member'

export default {
  name: 'ProfitDiscount',
  data() {
    return {
      loading: false,
      form: {
        dpLevelOne: 100,
        dpLevelTwo: 100,
        dpLevelThree: 100,
        merchantBtLevelOne: 0,
        merchantBtLevelTwo: 0,
        merchantBtLevelThree: 0,
        merchantVipLevelOne: 0,
        merchantVipLevelTwo: 0,
        merchantVipLevelThree: 0,
        merchantVipzLevelOne: 0,
        merchantVipzLevelTwo: 0,
        merchantVipzLevelThree: 0,
        merchantZxLevelOne: 0,
        merchantZxLevelTwo: 0,
        merchantZxLevelThree: 0,
        merchantZxzLevelOne: 0,
        merchantZxzLevelTwo: 0,
        merchantZxzLevelThree: 0,
        zxJumpStore: 0,
        zxJumpCenter: 0,
        vipSpecial: 0,
        zxSpecial: 0,
        vipCenterMemberPackage: 0,
        vipCenterMemberQuota: 0,
        zxPresidentMemberPackage: 0,
        zxPresidentMemberQuota: 0
      },
      rules: {
        dpLevelOne: [{ required: true, message: '请填写', trigger: 'change' }],
        dpLevelTwo: [{ required: true, message: '请填写', trigger: 'change' }],
        dpLevelThree: [{ required: true, message: '请填写', trigger: 'change' }],
        merchantBtLevelOne: [{ required: true, message: '请填写', trigger: 'change' }],
        merchantBtLevelTwo: [{ required: true, message: '请填写', trigger: 'change' }],
        merchantBtLevelThree: [{ required: true, message: '请填写', trigger: 'change' }],
        merchantVipLevelOne: [{ required: true, message: '请填写', trigger: 'change' }],
        merchantVipLevelTwo: [{ required: true, message: '请填写', trigger: 'change' }],
        merchantVipLevelThree: [{ required: true, message: '请填写', trigger: 'change' }],
        merchantVipzLevelOne: [{ required: true, message: '请填写', trigger: 'change' }],
        merchantVipzLevelTwo: [{ required: true, message: '请填写', trigger: 'change' }],
        merchantVipzLevelThree: [{ required: true, message: '请填写', trigger: 'change' }],
        merchantZxLevelOne: [{ required: true, message: '请填写', trigger: 'change' }],
        merchantZxLevelTwo: [{ required: true, message: '请填写', trigger: 'change' }],
        merchantZxLevelThree: [{ required: true, message: '请填写', trigger: 'change' }],
        merchantZxzLevelOne: [{ required: true, message: '请填写', trigger: 'change' }],
        merchantZxzLevelTwo: [{ required: true, message: '请填写', trigger: 'change' }],
        merchantZxzLevelOne: [{ required: true, message: '请填写', trigger: 'change' }],
        zxJumpStore: [{ required: true, message: '请填写', trigger: 'change' }],
        zxJumpCenter: [{ required: true, message: '请填写', trigger: 'change' }],
        vipSpecial: [{ required: true, message: '请填写', trigger: 'change' }],
        zxSpecial: [{ required: true, message: '请填写', trigger: 'change' }],
        vipCenterMemberPackage: [{ required: true, message: '请填写', trigger: 'change' }],
        vipCenterMemberQuota: [{ required: true, message: '请填写', trigger: 'change' }],
        zxPresidentMemberPackage: [{ required: true, message: '请填写', trigger: 'change' }],
        zxPresidentMemberQuota: [{ required: true, message: '请填写', trigger: 'change' }]
      },
      mobile: "",
      profile: "",
    }
  },
  created() {
    this.handleInitial()
    this.$form.createForm(this)
  },
  methods: {
    handleReset(e) {
      this.$refs.form.resetFields()
    },
    handleCancel(e) {
      this.$router.back()
    },
    handleInitial() {
      getDiscountConfig().then(config => {
        this.form = Object.assign({} , this.form , config)
      })
    },
    async handleSubmit(e) {
      e.preventDefault()
      this.$refs.form.validate((result) => {
        if (result) {
          this.loading = true;
          updateDiscountConfig(this.form).then(() => {
            this.$message.success({ content: '操作成功' })
            this.loading = false;
          }).catch(() => {
            this.$message.warning({ content: '操作失败' })
            this.loading = false;
          })
        }
      })
    },
    handleMobile() {
      // if (!/^1[3456789]\d{9}$/.test(this.mobile)) {
      //   this.$message.warning('请输入正确手机号')
      //   return;
      // }
      getMobileMember({mobile: this.mobile}).then((result) => {
        this.profile = result
      })
    },
    handleChangeUse(value) {
      putMember({
        id: this.profile.id,
        discountUseTag: value ? 1 : 0
      }).then(() => {
        this.profile.discountUseTag = value ? 1 : 0;
        this.$message.success({ content: '操作成功' })
      })
    },
    handelZhuXiao(){
      // this.mobile = "";
      deleteShareMember({
        id: this.profile.id
      }).then(() => {
        this.$message.success({ content: '注销成功' })
        this.mobile = "";
      })
    }
  }
}
</script>
